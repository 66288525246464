import AutoComplete from '@tarekraafat/autocomplete.js';

export default () => {
    const autocompleteInput = document.querySelector('#autocomplete');
    const clearAutocompleteButton = document.querySelector('.js-clear-autocomplete');
    const searchAutocompleteButton = document.querySelector('.js-search-autocomplete');

    if (!autocompleteInput) {
        return;
    }

    const config = {
        name: 'autosuggest',
        selector: '#autocomplete',
        submit: true,
        data: {
            src: async (q) => {
                // eslint-disable-next-line no-restricted-globals
                const urlString = `${location.origin}/autocomplete/`;
                const url = new URL(urlString);
                url.searchParams.append('q', q);

                const source = await fetch(url);
                const result = await source.json();

                return result[1];
            },
            cache: false,
        },
        resultsList: {
            tag: 'ul',
            class: 'autosuggest__results',
            destination: '#autocomplete',
            maxResults: 10,
        },
        resultItem: {
            tag: 'li',
            class: 'autosuggest__result',
            highlight: 'autosuggest__result--highlighted',
            selected: 'autosuggest__result--selected',
        },
    };

    // eslint-disable-next-line no-new
    const autocompleteObject = new AutoComplete(config);

    document.querySelector('#autocomplete').addEventListener('selection', (event) => {
        autocompleteInput.focus();
        autocompleteInput.value = event.detail.selection.value;
        autocompleteInput.closest('form').submit();
    });

    document.querySelector('#autocomplete').addEventListener('response', () => {
        if (autocompleteInput.value) {
            clearAutocompleteButton.classList.remove('hide');
        } else {
            clearAutocompleteButton.classList.add('hide');
        }
    });

    document.addEventListener('scroll', () => {
        autocompleteObject.close();
    });

    if (clearAutocompleteButton) {
        document.addEventListener('DOMContentLoaded', () => {
            const input = clearAutocompleteButton.parentElement.querySelector('input');

            if (input && input.value) {
                clearAutocompleteButton.classList.remove('hide');
            }
        });

        clearAutocompleteButton.addEventListener('click', (event) => {
            const input = event.target.parentElement.querySelector('input');
            input.value = '';
            input.focus();
            clearAutocompleteButton.classList.add('hide');
        });
    }

    if (searchAutocompleteButton) {
        searchAutocompleteButton.addEventListener('click', (event) => {
            const form = event.target.closest('form');
            form.submit();
        });
    }
};
